/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  touch-action: none;
}

canvas {
  touch-action: none;
  display: block;
}

html {
}

ul {
  list-style: disc;
  padding-left: 24px;
}
a {
  text-decoration: underline;
}

.action-button:hover {
  background: rgba(0, 0, 0, 0.125);
}
